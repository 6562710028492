  class CardProduct extends HTMLElement {
    constructor() {
      super();

      this.promo_message = '';
      this.promos = document.querySelectorAll('.js-promotext');
      this.productTags = this.querySelectorAll('.product-tag');
      this.tag_array = [];
      this.promoElem = this.querySelector('.promo-text')
    }

    connectedCallback() {
    this.productTags.forEach((elem) => {
    const tagValue = elem.dataset.tag;
    if (tagValue) {
      this.tag_array.push(tagValue);
    }
    });

    this.promos.forEach((elem) => {
      if (this.tag_array.includes(elem.dataset.tag)) {
        this.promoElem.textContent = elem.textContent
        this.promoElem.style.color = `${elem.dataset.color}`;
      }
    });
    }

  }

  customElements.define('card-product', CardProduct);
